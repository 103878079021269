import { Layout } from 'antd';
import { useState } from 'react';
import UIComponent from './UIComponent';
import HistoryComponent from './HistoryComponent';


const { Content } = Layout;

export default function ContentComponent({ onLogout }) {
	const handleLogout = () => onLogout(false);
	const [tab, setTab] = useState('dashboard');

	return (
		<Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
			<div style={{ display: 'flex' }}>
				<div
					className="tab"
					active={tab == 'dashboard' ? 'true' : 'false'}
					onClick={(e) => {
						setTab('dashboard');
					}}
				>
					Kokpit
				</div>
				<div
					className="tab"
					active={tab == 'history' ? 'true' : 'false'}
					onClick={(e) => {
						setTab('history');
					}}
				>
					Historia
				</div>
			</div>
			<div className="site-layout-background" style={{ padding: 24, minHeight: 380 }}>
				{tab == 'dashboard' ? <UIComponent onLogout={handleLogout} /> : <HistoryComponent />}
			</div>
		</Content>
	);
}
