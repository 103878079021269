import {
  Input,
  Space,
  Button,
  Col,
  Row,
  message,
  BackTop,
  Progress,
  Statistic,
  Drawer,
  Tooltip,
  notification,
  Typography,
} from "antd";
import {
  LinkOutlined,
  ExportOutlined,
  StopOutlined,
  PlayCircleOutlined,
  ArrowUpOutlined,
  LogoutOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { useState, useEffect } from "react";
import axios from "./../axios.js";
import moment from "moment";
import CSVTable from "./CSVTable.jsx";
import TableComponent from "./TableComponent.jsx";
const fileDownload = require("js-file-download");
const { Text } = Typography;

export default function UIComponent({ onLogout }) {
  const [url, setUrl] = useState("");
  const [processing, setProcessing] = useState(false);
  const [open, setOpen] = useState(false);
  const [finished, setFinished] = useState(false);
  const [user, setUser] = useState({});
  const [currentScraping, setCurrentScraping] = useState(null);
  const [records, setRecords] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    let isFetchingUser = false;
    let isFetchingScraping = false;

    async function fetchWithFlag() {
      if (!isFetchingUser) {
        isFetchingUser = true;
        checkConnection();

        getUser().finally(() => {
          isFetchingUser = false;
        });
      }

      if (!isFetchingScraping) {
        isFetchingScraping = true;

        getCurrentScraping().finally(() => {
          isFetchingScraping = false;
        });
      }
    }

    fetchWithFlag();
    const intervalId = setInterval(fetchWithFlag, 3000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    openFinishNotification();
  }, [finished]);

  useEffect(() => {
    if (currentScraping) {
      getRecords(currentScraping._id);
    }    
  }, [currentScraping]);

  const getRecords = async (scrapingId) => {
    const res = await axios
      .post("/api/records", { scrapingId })
      .catch((err) => {
        console.error(err);
      });

    if (res) {
      let data = res.data;
      data = data.map((record) => {
        // Delete unwanted properties
        delete record._id;
        delete record.scrapingId;
        delete record.__v;

        return record;
      });

      setRecords(data);
      setTableLoading(false);
    }
  };

  const handleExport = async () => showDrawer();
  const showDrawer = () => setOpen(true);

  const checkConnection = async () => {
    let res = await axios.get("/api/ping").catch((error) => {
      window.location.reload();
      message.error("Brak polączenia z serwerem");
    });
    if (!res) {
      window.location.reload();
      message.error("Brak polączenia z serwerem");
    }
  };

  const openFinishNotification = () => {
    if (finished)
      notification.success({
        message: `Pobieranie zakończone`,
        description:
          'Właśnie skończylo się pobieranie danych. Możesz je pobrać klikając w przycisk Eksportuj"',
        icon: <CheckCircleOutlined style={{ color: "#53C31B" }} />,
        duration: null,
      });
  };

  const handleLogout = () => {
    localStorage.removeItem("auth");
    onLogout(false);
  };

  const getUser = async () => {
    const res = await axios.get("/api/user").catch((err) => {
      console.error(err);
    });
    setUser(res.data);
  };

  const getCurrentScraping = async () => {
    const res = await axios.get("/api/getCurrentScraping").catch((err) => {
      console.error(err);
    });

    if (res) {
      setCurrentScraping(res.data);
      setProcessing(res.data.isRunning);
      setFinished(res.data.isFinished);
      setUrl(res.data.initialURL);
    }
  };

  const handleStart = async () => {
    if (url === "" || !url) return message.error("Nie podano linku");
    if (
      !url.includes("olx.pl/") &&
      !url.includes("otodom.pl/") &&
      !url.includes("otomoto.pl/") &&
      !url.includes("trojmiasto.pl/")
    )
      return message.error("Niepoprawny link");

    const res = await axios.post("/api/start", { url }).catch((err) => {
      console.error(err.response.data.message);
      if (err.response.status === 500) message.error(err.response.data.message);
      // else message.error('Błąd');
      setProcessing(false);
    });

    if (res) {
      message.loading({
        content: "Pobieranie w toku",
        key: "updatable",
        duration: 3,
      });
      // socket.emit('start');
      setProcessing(true);
      setFinished(false);
    }
  };

  const handleStop = async () => {
    const res = await axios
      .post("/api/stop", {
        scrapingId: currentScraping._id,
      })
      .catch((err) => {
        console.error(err);
      });
    if (res) {
      setProcessing(false);
      message.info("Pobieranie zatrzymane");
    }
  };

  const handleDownload = async (scrapingId) => {
    const res = await axios({
      url: "/api/export",
      method: "POST",
      params: { scrapingId },
      responseType: "blob",
    }).catch((err) => {
      console.error(err);
    });
    fileDownload(res.data, `rekordy-${moment().format("YYYY-MM-DD")}.csv`);
    message.info("Pobieranie pliku");
  };

  const handleChange = (event) => {
    setUrl(event.target.value)
  };

  const onClose = () => setOpen(false);

  return (
    <Space direction="vertical" size="large" style={{ display: "flex" }}>
      <Row gutter={[16, 16]}>
        <Col
          xs={{ span: 24, order: 2 }}
          md={{ span: 12, order: 2 }}
          lg={{ span: 4, order: 1 }}
        >
          <Statistic title="Zuycie planu" value={user.usage} />
        </Col>
        <Col
          xs={{ span: 24, order: 3 }}
          md={{ span: 12, order: 3 }}
          lg={{ span: 4, order: 2 }}
        >
          <Statistic title="Limit" value={user.limit} />
        </Col>
        <Col
          xs={{ span: 24, order: 4 }}
          md={{ span: 12, order: 4 }}
          lg={{ span: 4, order: 3 }}
        >
          <Statistic title="Pozostalo" value={user.limit - user.usage} />
        </Col>
        <Col
          xs={{ span: 24, order: 5 }}
          md={{ span: 12, order: 5 }}
          lg={{ span: 6, order: 4 }}
        >
          <Statistic title="Wyzerowanie limitu" value={user.nextPaymentDate} />
        </Col>
        <Col
          xs={{ span: 24, order: 1 }}
          md={{ span: 24, order: 1 }}
          lg={{ span: 6, order: 5 }}
        >
          <Row justify="end">
            <Space>
              <Tooltip title="Wyloguj się">
                <Button
                  size="large"
                  danger
                  onClick={handleLogout}
                  icon={<LogoutOutlined />}
                />
              </Tooltip>
            </Space>
          </Row>
        </Col>
      </Row>
      <Progress
        strokeColor={{
          from: "#108ee9",
          to: "#87d068",
        }}
        percent={((user.usage / user.limit) * 100).toFixed(1)}
        status="active"
      />
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={18}>
          <Input
            placeholder="Link z OLX, OTODOM lub OTOMOTO"
            onChange={handleChange}
            value={url}
            disabled={processing}
            size="large"
            prefix={<LinkOutlined />}
          />
        </Col>
        <Col xs={6} lg={2}>
          <Tooltip title="Rozpocznij">
            <Button
              onClick={handleStart}
              type="primary"
              size="large"
              block
              loading={processing}
              icon={<PlayCircleOutlined />}
            />
          </Tooltip>
        </Col>
        <Col xs={6} lg={2}>
          <Tooltip title="Zatrzymaj">
            <Button
              onClick={handleStop}
              type="danger"
              size="large"
              block
              disabled={!processing}
              icon={<StopOutlined />}
            />
          </Tooltip>
        </Col>
        <Col xs={6} lg={2}>
          <Tooltip title="Eksportuj">
            <Button
              onClick={handleExport}
              type="secondary"
              size="large"
              block
              disabled={!records.length}
              icon={<ExportOutlined />}
            />
          </Tooltip>
        </Col>
      </Row>
      <Drawer
        title={"Export rekordów"}
        placement="right"
        onClose={onClose}
        open={open}
        width={80 + "%"}
        extra={
          <Space>
            <Button
              onClick={() => handleDownload(currentScraping._id)}
              type="primary"
            >
              Pobierz plik
            </Button>
          </Space>
        }
      >
        <CSVTable records={records} />
      </Drawer>
      <Row gutter={[16, 16]}>
        <Space direction="horizontal" size="large" style={{ width: "100%" }}>
          <Text>
            {processing
              ? "Pobieranie w toku"
              : finished
              ? "Pobieranie zakończone"
              : "Pobieranie zatrzymane"}
          </Text>
          <Text>
            {currentScraping &&
              (
                (currentScraping.currentPageNumber /
                  currentScraping.lastPageNumber) *
                100
              ).toFixed(0)}
            %
          </Text>
          <Text>
            {records.length
              ? records.length + " rekordów"
              : "Brak pobranych rekordów"}
          </Text>
          <Text>
            {currentScraping &&
              currentScraping.currentPageNumber +
                " strona z " +
                currentScraping.lastPageNumber}
          </Text>
        </Space>
      </Row>
      {records.length ? (
        <TableComponent records={records} tableLoading={tableLoading} />
      ) : (
        ""
      )}
      <BackTop>
        <div className="back-to-top">
          <ArrowUpOutlined />
        </div>
      </BackTop>
    </Space>
  );
}
