import { useEffect, useState } from "react";
import {
  Card,
  Space,
  Button,
  Tooltip,
  Descriptions,
  message,
  Drawer,
  Spin,
} from "antd";
import {
  PlayCircleOutlined,
  DeleteOutlined,
  ExportOutlined,
  PauseCircleOutlined,
} from "@ant-design/icons";
import { CsvToHtmlTable } from "react-csv-to-table";
import axios from "./../axios.js";
import moment from "moment";
import * as jsonexport from "jsonexport/dist";
import CSVTable from "./CSVTable.jsx";
import { json2csv } from "json-2-csv";

const fileDownload = require("js-file-download");

export default function HistoryComponent() {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isRunning, setIsRunning] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [data, setData] = useState([]);


  useEffect(() => {
    let isFetching = false;

    function fetchWithFlag() {
      if (isFetching) return;
      isFetching = true;

      getHistory().finally(() => {
        isFetching = false;
      });
    }

    fetchWithFlag();
    const intervalId = setInterval(fetchWithFlag, 3000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    history.find((scraping) => scraping.isRunning)
      ? setIsRunning(true)
      : setIsRunning(false);
  }, [history]);

  const getHistory = async () => {
    axios.get("/api/history").then((res) => {
      setHistory(res.data);
      setLoading(false);
    });
  };

  const start = async (scrapingId) => {
    const res = await axios.post("/api/start", { scrapingId }).catch((err) => {
      console.error(err.response.data.message);
    });

    if (res) {
      message.loading({
        content: "Pobieranie w toku",
        key: "updatable",
        duration: 3,
      });
      setIsRunning(true);
      await getHistory();
    }
  };

  const stop = async (scrapingId) => {
    console.log("scrapingID:", scrapingId);
    let res = await axios.post("/api/stop", { scrapingId });
    if (res) {
      setIsRunning(false);
      await getHistory();
      message.info("Skanowanie zatrzymane");
    }
  };

  const getRecords = async (scrapingId) => {
    const res = await axios
      .post("/api/records", { scrapingId })
      .catch((err) => {
        console.error(err);
      });

    if (res) {
      let data = res.data;
      data.forEach((record) => {
        delete record._id;
        delete record.scrapingId;
        delete record.__v;
      });
      setData(data);
    }
  };

  const deleteScraping = async (scrapingId) => {
    const res = await axios.post("/api/delete", { scrapingId }).catch((err) => {
      console.error(err.response.data.message);
    });

    if (res) {
      message.info("Pomyślnie usunięto");
      await getHistory();
    }
  };

  const showPreview = async (scrapingId) => {
    await getRecords(scrapingId);
    setOpenPreview(true);
  };

  const closePreview = () => setOpenPreview(false);

  const handleDownload = async (scrapingId) => {
    const res = await axios({
      url: "/api/export",
      method: "POST",
      params: { scrapingId },
      responseType: "blob",
    }).catch((err) => {
      console.error(err);
    });
    fileDownload(res.data, `rekordy-${moment().format("YYYY-MM-DD")}.csv`);
    message.info("Pobieranie pliku");
  };

  return (
    <>
      <Space direction="vertical" size={16} style={{ width: "100%" }}>
        {loading && (
          <div
            style={{
              width: 100 + "%",
              height: 400,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin size="large" />
          </div>
        )}
        {!loading &&
          history.map((scraping) => (
            <Card
              title={"ID: " + scraping._id}
              key={scraping._id}
              loading={loading}
              extra={
                <Space direction="horizontal" size={16}>
                  <Tooltip title="Eksportuj">
                    <Button
                      type="secondary"
                      onClick={() => showPreview(scraping._id)}
                      size="large"
                      icon={<ExportOutlined />}
                    />
                  </Tooltip>
                  <Tooltip title="Kontynuuj pobieranie">
                    {scraping.isRunning ? (
                      <Button
                        type="primary"
                        onClick={() => stop(scraping._id)}
                        size="large"
                        icon={<PauseCircleOutlined />}
                      />
                    ) : (
                      <Button
                        type="primary"
                        onClick={() => start(scraping._id)}
                        size="large"
                        disabled={isRunning && !scraping.isRunning}
                        icon={<PlayCircleOutlined />}
                      />
                    )}
                  </Tooltip>
                  <Tooltip title="Usuń">
                    <Button
                      type="danger"
                      onClick={() => deleteScraping(scraping._id)}
                      size="large"
                      disabled={scraping.isRunning || isRunning}
                      icon={<DeleteOutlined />}
                    />
                  </Tooltip>
                </Space>
              }
            >
              <Descriptions title={scraping.initialURL}>
                <Descriptions.Item label="Status">
                  {scraping.isFinished
                    ? "Zakończone"
                    : scraping.isRunning
                    ? "Aktywna"
                    : "Zatrymane"}
                </Descriptions.Item>
                <Descriptions.Item label="Aktualna strona">
                  {scraping.currentPageNumber}
                </Descriptions.Item>
                <Descriptions.Item label="Ilość stron">
                  {scraping.lastPageNumber}
                </Descriptions.Item>
                <Descriptions.Item label="Data rozpoczęcia">
                  {moment(scraping.createdAt).format("YYYY-MM-DD – hh:mm")}
                </Descriptions.Item>
                <Descriptions.Item label="">
                  {(
                    (scraping.currentPageNumber / scraping.lastPageNumber) *
                    100
                  ).toFixed(0)}
                  %
                </Descriptions.Item>
              </Descriptions>

              <Drawer
                title={"Export rekordów"}
                placement="right"
                onClose={closePreview}
                open={openPreview}
                width={80 + "%"}
                extra={
                  <Space>
                    <Button
                      onClick={() => handleDownload(scraping._id)}
                      type="primary"
                    >
                      Pobierz plik
                    </Button>
                  </Space>
                }
              >
                <CSVTable records={data} />
              </Drawer>
            </Card>
          ))}
      </Space>
    </>
  );
}
