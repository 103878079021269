import { Table } from "antd";

export default function CSVTable({ records }) {
  let allKeys = Array.from(new Set(records.flatMap(Object.keys)));
  // Initialize an ordered list of keys
  let orderedKeys = [];

  // Conditionally add 'images' and 'title' if they exist
  if (allKeys.includes('images')) {
    orderedKeys.push('images');
  }
  if (allKeys.includes('title')) {
    orderedKeys.push('title');
  }

  // Add remaining keys, excluding 'images' and 'title'
  orderedKeys = orderedKeys.concat(allKeys.filter(key => key !== 'images' && key !== 'title'));


  return (
    <Table
      dataSource={records}
      size="small"
      pagination={{ pageSize: 50 }}
      scroll={{
        x: 2000,
      }}
      columns={orderedKeys.map((key) => {
        return {
          title: key.charAt(0).toUpperCase() + key.slice(1),
          dataIndex: key,
          key: key,
          width: 100,
          // textWrap: 'word-break',
          ellipsis: true,
          render: (text, record) => {
            if (key === "images") {
              return JSON.stringify(text);
            }

            return text;
          },
        };
      })}
    />
  );
}
